




import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "ProductMainPage"
})
export default class ProductMainPage extends Vue {}
